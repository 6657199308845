<template>
  <layout width="540">
    <template slot="header">
      Links
    </template>
    <template>
      <v-form>
        <form-subheader title="Monitoring items" />
        <div
          v-for="monitoringItem in monitoringItemsList"
          :key="monitoringItem.id"
          class="mb-6"
        >
          <form-subheader
            class="text--secondary"
            :title="monitoringItem.infoName"
          />
          <div
            v-if="!!monitoringItems[monitoringItem.id]"
            class="d-flex gc-4 flex-1-2"
          >
            <v-select
              v-model="monitoringItems[monitoringItem.id].id"
              label="Source name"
              item-value="id"
              item-text="name"
              :items="sources"
              clearable
              @click:clear="
                monitoringItems = {
                  ...monitoringItems,
                  [monitoringItem.id]: { id: '', propertyId: '' }
                }
              "
            />
            <v-select
              v-model="monitoringItems[monitoringItem.id].propertyId"
              label="Property name"
              item-value="id"
              item-text="name"
              :items="properties(monitoringItems[monitoringItem.id].id)"
              clearable
              @click:clear="monitoringItems[monitoringItem.id].propertyId = ''"
            />
          </div>
        </div>
        <form-subheader title="Management items" />
        <div
          v-for="managementItem in managementItemsList"
          :key="managementItem.id"
          class="mb-6"
          :data-param="managementItem.id"
        >
          <template v-if="!!managementItems[managementItem.id]">
            <form-subheader
              class="text--secondary"
              :title="managementItem.infoName"
            />
            <div class="d-flex gc-4 flex-1-2">
              <v-select
                v-model="managementItems[managementItem.id].sourceId"
                label="Source name"
                item-value="id"
                item-text="name"
                :items="sources"
                clearable
                @click:clear="
                  managementItems = {
                    ...managementItems,
                    [managementItem.id]: { sourceId: '', rpc: null }
                  }
                "
              />
              <v-select
                v-model="managementItems[managementItem.id].rpc"
                label="Control name"
                item-value="rpc"
                item-text="name"
                :items="controls[managementItems[managementItem.id].sourceId]"
                clearable
                @click:clear="managementItems[managementItem.id].rpc = null"
              />
            </div>
          </template>
        </div>
      </v-form>
    </template>
    <template slot="footer">
      <v-spacer />
      <v-btn text color="text-primary" @click.stop="$emit('close')">
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        depressed
        :loading="loading"
        @click.stop="submit"
      >
        Save
      </v-btn>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault.vue';
import { ref, onMounted, nextTick, watch } from '@vue/composition-api';
import { usePromise } from 'vue-composable';
import { useMonitoringItems } from '@/modules/objects/compositions/monitoring-items';
import {
  linkedObjectService,
  linkedObjectsService
} from '@/modules/linked-objects/api';
import { abcSort } from '@/compositions/sortBy';
import { useManagementItems } from '@/modules/objects/compositions/management-items';
import { prepareObject } from '@/provider/utils';
import { objectPropertyService } from '@/modules/common/api';
export default {
  name: 'ManagementItemEdit',
  components: {
    Layout
  },
  props: {
    objectId: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const {
      load: loadMonitoringItems,
      list: monitoringItemsList
    } = useMonitoringItems({ objectId: props.objectId });
    const {
      load: loadManagementItems,
      list: managementItemsList
    } = useManagementItems({ objectId: props.objectId });
    const sources = ref([]);
    const managementItems = ref({});
    const monitoringItems = ref({});
    const controls = ref({});

    const properties = sourceId => {
      const source = sources.value.find(source => source.id === sourceId);
      const schemaProperties = source?.schema?.schemaProperties || [];
      const namesMap = schemaProperties.reduce((acc, cur) => {
        acc[cur.property] = cur.description || cur.property;
        return acc;
      }, {});

      return source?.objectProperties
        .map(i => ({ ...i, name: namesMap[i.property] }))
        .sort((a, b) => abcSort(a?.name, b?.name));
    };

    const getControls = async sourceId => {
      const source = sources.value.find(source => source.id === sourceId);
      if (!source) {
        return [];
      }
      const allControls =
        (await linkedObjectService.fetchControlsByObjectId(source.id)).schema
          ?.schemaControls || [];

      return allControls
        .filter(({ isRpc }) => !!isRpc)
        .map(({ rpc, description }) => ({
          name: description || rpc,
          rpc
        }))
        .sort((a, b) => abcSort(a?.name, b?.name));
    };

    onMounted(async () => {
      const [sourcesList] = await Promise.all([
        linkedObjectsService.fetch(props.objectId),
        loadMonitoringItems(),
        loadManagementItems()
      ]);

      sources.value = sourcesList;

      // add controls

      for await (const source of sources.value) {
        controls.value = {
          ...controls.value,
          [source.id]: await getControls(source.id)
        };
      }

      // init monitoring items

      monitoringItemsList.value.forEach(item => {
        monitoringItems.value[item.id] = {
          id: '',
          propertyId: ''
        };

        const linkedPropertyId = item.propertyValueObject?.linkedPropertyId;

        if (linkedPropertyId) {
          const source = sources.value.find(source =>
            source.objectProperties.some(
              property => property.id === linkedPropertyId
            )
          );
          if (source) {
            monitoringItems.value[item.id] = {
              id: source.id,
              propertyId: linkedPropertyId
            };
          }
        }
      });

      // init management items

      managementItemsList.value.forEach(item => {
        managementItems.value = {
          ...managementItems.value,
          [item.id]: {
            sourceId: '',
            rpc: ''
          }
        };
        const { sourceId, rpc } = item.stateControlRpc;

        if (sourceId) {
          const source = sources.value.find(source => source.id === sourceId);
          if (source) {
            managementItems.value[item.id] = {
              sourceId,
              rpc
            };
          }
        }
      });
    });

    const handleSubmit = async () => {
      // link monitoring items
      await Promise.all(
        monitoringItemsList.value.map(item => {
          return objectPropertyService.update(item.propertyValueObject.id, {
            linkedPropertyId: monitoringItems.value[item.id].propertyId
          });
        })
      );

      // link management items
      await Promise.all(
        managementItemsList.value.map(item => {
          objectPropertyService.update(item.stateControlRpcId, {
            value: managementItems.value[item.id]
          });
        })
      );
      emit('close');
    };

    const { loading, exec: submit } = usePromise(handleSubmit, true);

    const clearMonitoringItemSource = monitoringItemId => {
      delete monitoringItems.value[monitoringItemId];
    };

    return {
      loading,
      submit,
      monitoringItemsList,
      managementItemsList,
      sources,
      properties,
      managementItems,
      monitoringItems,
      clearMonitoringItemSource,
      controls
    };
  }
};
</script>
